<template>
    <div>
        <div class="chart">
            <div class="form-area">
                <div class="pt-3 pb-3">
                    <el-form ref="form" :inline="true" :model="formData.data" label-width="130px">
                        <el-form-item label="聊天记录类型">
                            <el-checkbox-group v-model="formData.data.subject">
                                <el-checkbox v-for="(item,key) in allSubject" :label="key" :key="key">{{item}}</el-checkbox>
                            </el-checkbox-group>
                        </el-form-item>
                        <el-form-item label="接收用户">
                            <el-input v-model="formData.data.user"></el-input>
                        </el-form-item>
                        <el-form-item label="发送时间">
                            <el-date-picker
                                    v-model="formData.data.time"
                                    type="date"
                                    value-format="timestamp"
                                    placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>
                    </el-form>
                    <div class="pl-5">
                        <el-button :loading="$store.state.loading" @click="search" type="primary" size="medium" icon="el-icon-search">搜索</el-button>
                    </div>
                </div>
            </div>
            <div class="message">
                <div v-show="longClick" class="long-click text-center mt-2">
                    <el-button type="default" size="mini" @click="cancelLongClick">取消</el-button>
                    <el-button type="warning" size="mini" @click="deleteItem" icon="el-icon-remove">删除</el-button>
<!--                    <el-button type="warning" size="mini" @click="addBlack" icon="el-icon-lock">加入黑名单</el-button>-->
                </div>
                <div @mousedown="mousedown" @mouseup="mouseup" ref="scroll-message" @wheel="scroll" style="height: 100%;overflow: auto">
                    <message :message="showMessage" :from="133">
                        <template v-slot:default="item">
                            <div class="item">
                                <label>
                                    <input class="c_checkbox" name="deleteData[]" :value="[item.item.to,item.item.guid,item.item._index].join('_')" v-if="longClick" type="checkbox">
                                    <span v-if="item.item.subject==='AAAAA'">
                                <el-link @click="download(item.item)">{{item.item.filename}}</el-link>
                            </span>
                                    <span v-else-if="item.item.subject==='BBBBB'&&item.item.content.type.indexOf('file')>-1">
                                        <span>
                                             <el-link @click="download(item.item)">{{item.item.content.content}}</el-link>
                                        </span>
                                    </span>
                                    <span v-else-if="item.item.subject==='BBBBB'&&item.item.content.type.indexOf('image')>-1">
                                        <img class="chart-image" :src="'http://api.bigemap.com/manage/mail/chart/image?user='+item.item.to+'&guid='+item.item.guid+'&filename='+item.item.content.content" alt="">
                                    </span>
                                    <span v-else-if="item.item.subject==='BBBBB'">
                                        <span>
                                            {{item.item.content&&item.item.content.content}}
                                        </span>
                                    </span>
                                    <span v-else>
                                        {{item.item.subject}}
                                        <br>
                                        {{item.item.content}}
                            </span>
                                    <span v-if="!longClick" class="operation">
                                    <el-link type="warning" @click="deleteItem([item.item.to,item.item.guid,item.item._index].join('_'))" icon="el-icon-remove"></el-link>
                                    <el-link type="warning"  class="ml-3" @click="addBlack(item.item.from)" icon="el-icon-lock"></el-link>
                            </span>
                                </label>
                            </div>
                        </template>
                    </message>
                    <div class="text-center mb-3" v-show="$store.state.loading||!formData.hasNext">
                        <el-tag type="info">{{loadingMessage}}</el-tag>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import message from "@/components/chart/message";
    let allSubject={AAAAA:'文件',BBBBB:'聊天内容',ELSE:'其它'};
    export default {
        name: "manage",
        components: {
            message
        },
        data: function () {
            return {
                message:[],
                loadingMessage:'数据加载中....',
                baseUrl:'/manage/mail',
                allSubject,
                deleteData:[],
                longClick:false,
                timer:{},
                formData: {
                    hasNext:true,
                    data: {
                        user:'',
                        next: '',
                        subject: ['AAAAA'],
                        time: new Date().getTime(),
                    }
                },
            }
        },
        mounted() {
            this.getData();
        },
        computed:{
            showMessage:function () {
                let hasElse=this.formData.data.subject.indexOf('ELSE')!==-1;
                let r=[];
                Object.keys(allSubject).map(v=>{
                    if (this.formData.data.subject.indexOf(v)===-1) r.push(v);
                });
                let result=[];
                this.message.map((v,index)=>{
                    v._index=index;
                    if (hasElse){
                        r.indexOf(v.subject)===-1?result.push(v):true;
                    }else{
                        this.formData.data.subject.indexOf(v.subject)>-1?result.push(v):true;
                        // return this.formData.data.subject.indexOf(v.subject)>-1;
                    }
                });
                return result;
            }
        },
        methods: {
            search:function(){
                if (this.$store.state.loading) return;
                this.formData.hasNext=true;
                this.loadingMessage='数据加载中....';
                this.formData.data.next='';
                this.message.length=0;
                this.getData();
            },
            getData: function () {
                this.$axios.get(this.baseUrl, {params: this.formData.data}).then(data => {
                    let sortData=data.data.data.sort((a,b)=>{return b.date-a.date});
                    let baseTime;
                    sortData=sortData.map(v=>{
                        try {
                            if (v.subject==='BBBBB'){
                                v.content=JSON.parse(v.content);
                            }
                        }catch {
                            v.content={content:'未知',type:['text']};
                        }
                        if (!baseTime||Math.abs(v.date-baseTime)>60*20){
                            baseTime=v.date;
                        }else{
                            v.date='';
                        }
                        return v;
                    });
                    this.message.push(...sortData);
                    if (!data.data.next){
                        this.loadingMessage='数据加载完成';
                        this.formData.hasNext=false;
                    }else{
                        this.formData.data.next=data.data.next;
                        if (!this.message.length){
                            this.getData();
                        }
                    }
                });
            },
            download:function(item){
                this.$axiosApp.get(this.baseUrl+'/download',{params:{user:item.to,guid:item.guid,filename:item.filename||item.content.content}}).then(data=>{
                    let dom=document.getElementById('global_download_link');
                    dom.setAttribute('href','http://api.bigemap.com'+data.data.url);
                    dom.setAttribute('download',item.filename);
                    dom.download=item.filename;
                    dom.click();
                });
            },
            getSelected:function(){
                let selected=[];
                [...document.querySelectorAll('.c_checkbox')].map(v=>{
                   if (v.checked) selected.push(v.value);
                });
                return selected;
            },
            deleteItem:function(item){
                let d=(typeof item === 'string')?[item]:this.getSelected();
                this.$confirm(`你真的要删除这 ${d.length} 条消息吗？`,'删除确认').then(()=>{
                    this.$axiosApp.delete(this.baseUrl,{data:{list:d}}).then(()=>{
                        let deleteIndex=[];
                        d.map(v=>{
                            let index=v.split('_').pop();
                            index=parseInt(index);
                            if (!isNaN(index)) deleteIndex.push(index);
                        });
                        deleteIndex.sort().reverse().map(v=>this.message.splice(v,1));
                        this.$message.success('删除成功!');
                    });
                });
            },
            addBlack:function(from){
                this.$confirm(`你真的要将 ${from} 加入黑名单吗？`,'操作确认').then(()=>{
                    this.$axiosApp.put(this.baseUrl+'/'+from).then(()=>{
                        this.$message.success('加入黑名单成功!');
                    });
                });
            },
            cancelLongClick:function(){
                this.longClick=false;
            },
            mousedown:function(){
                if (this.longClick) return;
                this.longClick=false;
                this.timer.longClick=setTimeout(()=>{
                    this.longClick=true;
                },1000);
            },

            mouseup:function(){
                clearTimeout(this.timer.longClick);
            },
            scroll:function () {
                let dom=this.$refs['scroll-message'];
                // console.log(dom.scrollHeight ,dom.clientHeight,dom.scrollTop);
                if (dom.scrollHeight<(dom.clientHeight+dom.scrollTop+20)){
                    if (this.$store.state.loading||!this.formData.hasNext) return;
                    this.getData();
                }
            }
        }
    }
</script>

<style scoped lang="less">
    .chart {
        background-color: #ffffff;
        .message {
            height: calc(100vh - 235px);
            border: 1px solid #f1f1f1;
            overflow: auto;
            position: relative;
            .long-click{
                background: transparent;
                left: 50%;
                margin-left: -130px;
                z-index: 9;
                top:0;
                position: absolute;
            }
            .item{
                .c_checkbox{
                    transform: translate(-3px,3px);
                }
                .chart-image{
                    max-height: 200px;
                    max-width: 1000px;
                    vertical-align: top;
                }
                .operation{
                    opacity: 0;
                    transform: translate(-50);
                    transition: all ease-in-out 0.2s;
                }
                &:hover{
                    .operation{
                        transform: translate(0);
                        opacity: 1;
                    }
                }
            }
        }
    }
</style>
